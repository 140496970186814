import { useMediaQuery } from "react-responsive";
export const Hotline = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      {!isTabletOrMobile ? (
        <p
          className="m-0 p-2 px-5"
          style={{
            fontSize: "14px",
            backgroundColor: "#00B1BB",
            color: "white",
          }}
        >
          Hubungi Kami : switapandutama.contact@gmail.com | (087) 889971958 | (081) 288350497
        </p>
      ) : (
        <div className="container-hotline">
          <p className="moving-text p-0 m-0">
            <img src="/logo_white.png" height={30} className="p-0 m-0 me-2" alt="logo" />
            Hubungi Kami : switapandutama.contact@gmail.com | (087) 889971958 | (081) 288350497
            <img src="/logo_white.png" height={30} className="p-0 m-0 ms-2" alt="logo" />
          </p>
        </div>
      )}
    </>
  );
};
