import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useMediaQuery } from "react-responsive";
import "react-loading-skeleton/dist/skeleton.css";

export const Hero = () => {
  const [imageUrl, setImageUrl] = useState("");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  useEffect(() => {
    const storage = getStorage(db);
    const storageRef = ref(storage, "public/hero.png");

    getDownloadURL(storageRef)
      .then((url) => {
        console.log("Download URL:", url);
        setImageUrl(url);
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }, []);

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="d-flex flex-1 flex-column w-100 h-100">
          <div
            className="d-flex flex-row w-100 h-auto"
            style={{
              backgroundColor: "#00B1BB",
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                height: "400px",
                zIndex: 20,
                width: "100%",
              }}
            >
              <img
                src="/hero.png"
                style={{
                  width: "100%", // Ubah menjadi 100% agar gambar menutupi area dengan lebar yang sesuai
                  height: "100%",
                  objectFit: "cover",
                }}
                className="w-100"
                alt="logo"
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "400px",
                display: "flex",
                zIndex: 200,
                flexDirection: "column",
                alignItems: "end", // Ubah menjadi center agar tombol berada di tengah
                justifyContent: "center",
                padding: "60px", // Kurangi jarak padding agar tombol tidak terlalu jauh dari teks
              }}
            >
              <h3 className="p-0 m-0 fw-bold text-white">
                Jelajahi dunia kesehatan
              </h3>
              <h5 className="p-0 m-0 mt-4 fw-bold text-white">Bersama kami</h5>
              <h5 className="p-0 m-0 mt-2 fw-bold text-white">
                Mitra kesehatan Anda
              </h5>
              <h5 className="p-0 m-0 mt-2 fw-bold text-white">
                yang dapat diandalkan
              </h5>
              <button
                className="py-2 px-5 rounded-pill mt-5 buttonHero"
                onClick={() => {
                  window.location.href = "/contact";
                }}
              >
                Hubungi Kami
              </button>
            </div>
          </div>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center p-5">
            <h5
              style={{
                fontWeight: "bold",
                color: "#00B1BB",
              }}
            >
              Selamat datang di PT Swita Pandutama!
            </h5>
            <p>
              Mari temukan alat-alat kesehatan terbaik di PT Swita Pandutama.
              Bersama, kita sehat dan bahagia!
            </p>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-1 flex-column w-100 h-100 mt-3">
          <div
            className="d-flex flex-row w-100 h-auto"
            style={{
              backgroundColor: "#00B1BB",
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                height: "400px",
                zIndex: 20,
                width: "50%",
              }}
            >
              <img
                src="/hero.png"
                style={{
                  width: "100%", // Ubah menjadi 100% agar gambar menutupi area dengan lebar yang sesuai
                  height: "100%",
                  objectFit: "cover",
                }}
                className="w-100"
                alt="logo"
              />
            </div>
            <div
              style={{
                width: "50%",
                position: "absolute",
                top: 0,
                right: 0,
                height: "400px",
                display: "flex",
                zIndex: 200,
                flexDirection: "column",
                alignItems: "center", // Ubah menjadi center agar tombol berada di tengah
                justifyContent: "center",
              }}
            >
              <h3 className="text-center p-0 m-0 fw-bold text-white">
                Jelajahi dunia kesehatan
              </h3>
              <h5 className="text-center p-0 m-0 mt-4 fw-bold text-white">
                Bersama kami
              </h5>
              <h5 className="text-center p-0 m-0 mt-2 fw-bold text-white">
                Mitra kesehatan Anda
              </h5>
              <h5 className="text-center p-0 m-0 mt-2 fw-bold text-white">
                yang dapat diandalkan
              </h5>
            </div>
            <button
              className="py-2 px-5 rounded-pill mt-5 buttonHero"
              style={{
                position: "absolute",
                bottom: 15,
                right: 0,
                left: 0,
                margin: "auto",
                alignSelf: "center",
                width: "fit-content",
              }}
              onClick={() => {
                window.location.href = "/contact";
              }}
            >
              Hubungi Kami
            </button>
          </div>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center p-5">
            <h5
              style={{
                fontWeight: "bold",
                color: "#00B1BB",
                textAlign: "center",
              }}
            >
              Selamat datang di PT Swita Pandutama!
            </h5>
            <p className="text-center">
              Mari temukan alat-alat kesehatan terbaik di PT Swita Pandutama.
              Bersama, kita sehat dan bahagia!
            </p>
          </div>
        </div>
      )}
    </>
  );
};
