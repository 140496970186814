import { useEffect, useState } from "react";
import {
  FullMetadata,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
} from "firebase/storage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const PageDocumentation = () => {
  const [imageUrls, setImageUrls] = useState<
    { url: string; metadata: FullMetadata }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, "product");
        const res = await listAll(storageRef);
        const promises = res.items.map(async (itemRef) => {
          const metadata = await getMetadata(itemRef);
          const fileName = metadata.name.toLowerCase();
          if (
            !fileName.includes(
              "whatsapp image 2024-04-25 at 10.38.24 pm.jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-04-25 at 10.38.36 pm.jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-04-25 at 10.38.35 pm (1).jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-04-25 at 10.38.35 pm.jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-04-25 at 7.28.10 pm.jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-04-25 at 7.28.12 pm (1).jpeg"
            ) &&
            !fileName.includes("whatsapp image 2024-04-25 at 7.28.12 pm.jpeg")
          ) {
            const url = await getDownloadURL(itemRef);
            return {
              url: url,
              metadata: metadata,
            };
          } else {
            return null; // Ignore this image
          }
        });

        const downloadsAndMetadata = await Promise.all(promises);
        // Fungsi untuk menyortir nama file
        const customSort = (
          a: { metadata: FullMetadata } | null,
          b: { metadata: FullMetadata } | null
        ) => {
          if (a === null || b === null) {
            return 0;
          }

          const order: { [key: string]: number } = {
            "WhatsApp Image 2024-05-11 at 6.20.40 PM.jpeg": 1,
            "WhatsApp Image 2024-05-11 at 6.18.44 PM.jpeg": 2,
            "WhatsApp Image 2024-05-11 at 6.18.49 PM.jpeg": 3,
            "WhatsApp Image 2024-05-11 at 6.18.49 PM (1).jpeg": 4,
          };

          return order[a.metadata.name] - order[b.metadata.name];
        };

        // Ubah metode sortir menjadi:
        downloadsAndMetadata.sort(customSort);
        const filteredDownloadsAndMetadata = downloadsAndMetadata.filter(
          (item) => item !== null
        ) as { url: string; metadata: FullMetadata }[];

        setImageUrls(filteredDownloadsAndMetadata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(imageUrls, "check url");

  return (
    <div className="d-flex flex-1 w-100 h-100 min-h-100 flex-column p-4 align-items-center justify-content-center">
      <h2>Media</h2>
      <p>Dokumentasi / Arsip</p>
      <div>
        {imageUrls.length > 0 ? (
          <div id="carouselExampleCaptions" className="carousel slide">
            <div className="carousel-indicators">
              {imageUrls.map((_, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to={index}
                  className={index === 0 ? "active" : ""}
                  aria-label={`Slide ${index + 1}`}
                ></button>
              ))}
            </div>
            {/* Daftar gambar */}
            <div className="carousel-inner">
              {imageUrls.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={item.url}
                      className="d-block w-100"
                      style={{
                        // objectFit: "cover",
                        height: "auto",
                        maxHeight: "75vh",
                        width: "75vh",
                      }}
                      alt={`Slide ${index + 1}`}
                    />
                  </a>
                  <div className="carousel-caption d-none d-md-block text-secondary">
                    <h5>{item.metadata.name}</h5>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev text-secondary"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon bg-secondary"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next text-secondary"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon bg-secondary"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        ) : (
          <Skeleton
            style={{
              width: '90vw',
              height: "400px",
            }}
          />
        )}
      </div>
    </div>
  );
};
