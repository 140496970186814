import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageAbout from "./pages/about_us";
import { PageService } from "./pages/service";
import { PageDocumentation } from "./pages/documentation";
import { PageProduct } from "./pages/product";
import { PageContact } from "./pages/contact_us";

import Home from "./pages/home";
import { Copyright } from "./components/copyright";
import { Hotline } from "./components/hotline";
import { Navbar } from "./components/navbar";

const App = () => {
  return (
    <div className="app-container">
      <div className="d-flex flex-column h-100">
        <Hotline />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<PageAbout />} />
          <Route path="/service" element={<PageService />} />
          <Route path="/product" element={<PageProduct />} />
          <Route path="/documentation" element={<PageDocumentation />} />
          <Route path="/contact" element={<PageContact />} />
        </Routes>
        <Copyright />
      </div>
    </div>
  );
};

export default App;
