export const Copyright = () => {
  return (
    <footer 
      className="py-3"
      style={{
        backgroundColor: "#00B1BB",
        color: "white",
        textAlign: 'center',
        marginTop: 'auto',
        width: '100%'
      }}
    >
      <div className="container">
        <p className="m-0">
          &copy; 2024 - PT. Swita Pandu Tama | Perusahaan Supplier Alat Kesehatan, Perdagangan Retail dan Konsultan Indonesia
        </p>
      </div>
    </footer>
  );
};
