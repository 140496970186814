import React, { useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { db } from "../../firebase";
import { useMediaQuery } from "react-responsive";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PageAbout = () => {
  const [imageUrl, setImageUrl] = useState("");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  useEffect(() => {
    const storage = getStorage(db);
    const storageRef = ref(
      storage,
      "product/WhatsApp Image 2024-04-25 at 10.38.35 PM.jpeg"
    );

    getDownloadURL(storageRef)
      .then((url) => {
        setImageUrl(url);
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }, []);

  return (
    <div className="d-flex flex-1 flex-column p-5 min-h-100 w-100 my-5">
      <div className="row">
        {!isTabletOrMobile && (
          <div className="col-md-4">
            {imageUrl ? (
              <img
                src={imageUrl}
                className="img rounded"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                alt="about us"
              />
            ) : (
              <Skeleton
                style={{
                  height: "400px",
                }}
              />
            )}
          </div>
        )}
        <div
          className={
            isTabletOrMobile
              ? "col-12 bg-light rounded mb-4 p-2"
              : "col-md-8 bg-light rounded mb-4"
          }
        >
          <h1 className="mb-4">PT. SWITA PANDU TAMA</h1>
          <p className="fs-5 text-muted">
            Didirikan pada tahun 2024 yang berlokasi di Pamulang, Kota Tangerang
            Selatan. Perusahaan kami berfokus pada perdagangan retail & supplier
            alat kesehatan dan jasa skala Nasional. Meskipun tergolong baru
            namun kami akan terus berinovasi dan memberikan pelayanan terbaik.
          </p>
        </div>
        {isTabletOrMobile && (
          <div className="col-12">
            {imageUrl ? (
              <img
                src={imageUrl}
                className="img rounded"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                alt="about us"
              />
            ) : (
              <Skeleton
                style={{
                  height: "400px",
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="my-5 row">
        <div className="col-md-6 mb-3">
          <div className="bg-light p-4 rounded pb-5">
            <h2 className="mb-4">VISI</h2>
            <p className="mb-4 fs-4 text-muted">
              Menjadi perusahaan perdagangan retail & supplier alat kesehatan
              dan jasa skala Nasional
            </p>
          </div>
          <div className="mt-1 bg-light p-4 rounded pb-5">
            <h2 className="mb-4">MISI</h2>
            <ul className="list-unstyled">
              <li className="mb-3 text-muted fs-4">
                Memberikan jaminan produk berkualitas tinggi
              </li>
              <li className="mb-3 text-muted fs-4">
                Memberi pelayanan konsumen dengan kemudahan dan responsif
              </li>
              <li className="mb-3 text-muted fs-4">
                Melayani kebutuhan jasa dengan pelayanan prima dan memberi
                solusi terbaik
              </li>
              <li className="mb-3 text-muted fs-4">
                Terus berinovasi dalam pelayanan
              </li>
              <li className="mb-3 text-muted fs-4">Komitmen dan terpercaya</li>
            </ul>
          </div>
        </div>
        <div
          className="col-md-6 p-4 h-auto rounded"
          style={{ backgroundColor: "#00B1BB" }}
        >
          <div className="text-white">
            <h2 className="mb-4">COMPANY VALUES</h2>
            <ul className="list-group">
              <li
                className="list-unstyled border-0 rounded p-3 mb-2"
                style={{ backgroundColor: "#0097b6" }}
              >
                <h3 className="mb-3">Inovasi</h3>
                <p>
                  Kami percaya bahwa inovasi adalah kunci untuk memberikan
                  solusi terbaik dalam industri
                </p>
              </li>
              <li
                className="list-unstyled border-0 rounded p-3 mb-2"
                style={{ backgroundColor: "#0097b6" }}
              >
                <h3 className="mb-3">Kualitas</h3>
                <p>
                  Kami berkomitmen untuk memberikan produk-produk dengan standar
                  kualitas tertinggi
                </p>
              </li>
              <li
                className="list-unstyled border-0 rounded p-3 mb-2"
                style={{ backgroundColor: "#0097b6" }}
              >
                <h3 className="mb-3">Kepuasan Pelanggan</h3>
                <p>
                  Kepuasan pelanggan adalah prioritas utama kami, dan kami
                  selalu berusaha untuk memenuhi dan melebihi harapan mereka
                </p>
              </li>
              <li
                className="list-unstyled border-0 rounded p-3 mb-2"
                style={{ backgroundColor: "#0097b6" }}
              >
                <h3 className="mb-3">Integritas</h3>
                <p>
                  Kami menghargai integritas dan etika dalam semua aspek bisnis
                  kami
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
