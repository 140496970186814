import { useMediaQuery } from "react-responsive";

export const PageService = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      <div className="d-flex flex-1 flex-column p-5 min-h-100 w-100 my-5">
        <div className="text-center border-bottom mb-5">
          <h1 className="mb-4">APA YANG KAMI BERIKAN?</h1>
          <p className="fs-5">
            Kami adalah Perusahaan Suplier Alat Kesehatan, Perdagangan Retail dan Konsultan Indonesia, kami
            mengutamakan kepuasan pelanggan dengan memberikan pelayanan terbaik.
          </p>
        </div>
        <div className={`row gx-4 ${isTabletOrMobile ? 'flex-column align-items-center' : 'justify-content-between'}`}>
          <div className={`col-md-${isTabletOrMobile ? '10' : '5'} mb-4`} style={{ backgroundColor: "#00B1BB", borderRadius: '15px', padding: '20px' }}>
            <div className="text-center mb-4 text-white">
              <i className="bi bi-people-fill fs-1 mb-4"></i>
              <p className="fs-6 rounded p-2" style={{ backgroundColor: "#0097b6" }}>
                Mitra terpercaya dalam menyediakan alat kesehatan inovatif yang
                meningkatkan kualitas hidup pasien dan efisiensi para profesional
                kesehatan
              </p>
            </div>
          </div>
          <div className={`col-md-${isTabletOrMobile ? '10' : '5'} mb-4`} style={{ backgroundColor: "#00B1BB", borderRadius: '15px', padding: '20px' }}>
            <div className="text-center mb-4 text-white">
              <i className="bi bi-person-vcard fs-1 mb-4"></i>
              <p className="fs-6 rounded p-2" style={{ backgroundColor: "#0097b6" }}>
                Menyediakan solusi jasa terbaik dengan pelayanan yang berkualitas
                tinggi, inovatif, dan terpercaya untuk memenuhi kebutuhan dan
                harapan pelanggan kami
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
