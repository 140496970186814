import React from 'react';
import { Hero } from '../../components/hero';

const Home = () => {
  console.log("Home");
  
  return (
    <>
        <Hero />
    </>
  );
};

export default Home;
