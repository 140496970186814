import { useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { db } from "../../firebase";
import { useMediaQuery } from "react-responsive";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const PageContact = () => {
  const [imageUrl, setImageUrl] = useState("");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  useEffect(() => {
    const storage = getStorage(db);
    const storageRef = ref(storage, "public/dummy_map.jpeg");

    getDownloadURL(storageRef)
      .then((url) => {
        setImageUrl(url);
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }, []);

  return (
    <>
      <div className="w-auto p-3 mt-3 m-0 h-50">
        {imageUrl ? (
          <img src={imageUrl} className="w-100 h-100" alt="map_dummy" />
        ) : (
          <Skeleton
            style={{
              width: isTabletOrMobile ? '90vw' : '97vw',
              height: "200px",
              alignSelf: 'center',
              margin: 'auto'
            }}
          />
        )}
      </div>
      {!isTabletOrMobile ? (
        <div className="d-flex flex-1 w-100 min-h-100 flex-row">
          <div className="d-flex flex-1 flex-column w-100 h-100 align-items-start justify-content-start px-5">
            <h4 className="pt-4 fw-bold fs-4">PT SWITA PANDU TAMA</h4>
            <p className="p-0 m-0 pt-3">Graha Indah Pamulang Blok B6/7</p>
            <p className="p-0 m-0">Pamulang, Tangerang Selatan</p>
            <p className="p-0 m-0 pb-3">Banten. 15415</p>
            <div
              style={{
                width: "80%",
                borderBottom: "1px solid gray",
                opacity: "0.3",
              }}
            ></div>
            <p className="p-0 m-0 pt-2">Telp : (087) 889971958 | (081) 288350497</p>
            <p className="p-0 m-0 pb-3">
              Email : switapandutama.contact@gmail.com
            </p>
          </div>

          <div className="d-flex flex-1 flex-column w-100 h-100 align-items-start justify-content-start px-5">
            <h4 className="pt-4 fw-bold fs-4">Konsultasikan Bersama Kami</h4>
            <form className="pt-2 w-75">
              <div className="mb-3">
                <label htmlFor="inputName" className="form-label fw-bold">
                  Nama Lengkap
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  aria-describedby="emailHelp"
                  placeholder="Masukkan nama lengkap anda"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label fw-bold"
                >
                  Alamat Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Masukkan alamat email anda"
                />
                <div
                  id="emailHelp"
                  className="form-text"
                  style={{ opacity: "0.5" }}
                >
                  Kami akan menjaga kerahasiaan data anda.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="inputTel" className="form-label fw-bold">
                  Nomor Telepon
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="inputTel"
                  placeholder="Masukkan nomor telepon anda"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputTitle" className="form-label fw-bold">
                  Judul Pesan
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputTitle"
                  placeholder="Masukkan topik konsultasi anda"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea"
                  className="form-label fw-bold"
                >
                  Isi Pesan
                </label>
                <textarea
                  className="form-control"
                  placeholder="Ceritakan dan konsultasikan kebutuhan anda"
                  id="floatingTextarea"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary mb-5 mt-3 w-100">
                Submit
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-1 w-100 min-h-100 flex-column">
          <div className="d-flex flex-1 flex-column w-100 h-100 align-items-start justify-content-start px-5">
            <h4 className="pt-4 fw-bold fs-4">PT SWITA PANDU TAMA</h4>
            <p className="p-0 m-0 pt-3">Graha Indah Pamulang Blok B6/7</p>
            <p className="p-0 m-0">Pamulang, Tangerang Selatan</p>
            <p className="p-0 m-0 pb-3">Banten. 15415</p>
            <div
              style={{
                width: "80%",
                borderBottom: "1px solid gray",
                opacity: "0.3",
              }}
            ></div>
            <p className="p-0 m-0 pt-2">Telp : (087) 889971958 | (081) 288350497</p>
            <p className="p-0 m-0 pb-3">
              Email : switapandutama.contact@gmail.com
            </p>
          </div>

          <div className="d-flex flex-1 flex-column w-100 h-100 align-items-start justify-content-start px-5">
            <h4 className="pt-4 fw-bold fs-4">Konsultasikan Bersama Kami</h4>
            <form className="pt-2 w-100">
              <div className="mb-3">
                <label htmlFor="inputName" className="form-label fw-bold">
                  Nama Lengkap
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  aria-describedby="emailHelp"
                  placeholder="Masukkan nama lengkap anda"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label fw-bold"
                >
                  Alamat Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Masukkan alamat email anda"
                />
                <div
                  id="emailHelp"
                  className="form-text"
                  style={{ opacity: "0.5" }}
                >
                  Kami akan menjaga kerahasiaan data anda.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="inputTel" className="form-label fw-bold">
                  Nomor Telepon
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="inputTel"
                  placeholder="Masukkan nomor telepon anda"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputTitle" className="form-label fw-bold">
                  Judul Pesan
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputTitle"
                  placeholder="Masukkan topik konsultasi anda"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea"
                  className="form-label fw-bold"
                >
                  Isi Pesan
                </label>
                <textarea
                  className="form-control"
                  placeholder="Ceritakan dan konsultasikan kebutuhan anda"
                  id="floatingTextarea"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary mb-5 mt-3 w-100">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
