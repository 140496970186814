import { useEffect, useState } from "react";

import {
  FullMetadata,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
} from "firebase/storage";
import { useMediaQuery } from "react-responsive";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const PageProduct = () => {
  const [imageUrls, setImageUrls] = useState<
    { url: string; metadata: FullMetadata }[]
  >([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  console.log(imageUrls, "haio");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, "product");
        const res = await listAll(storageRef);
        const promises = res.items.map(async (itemRef) => {
          const metadata = await getMetadata(itemRef);
          const fileName = metadata.name.toLowerCase();
          if (
            !fileName.includes(
              "whatsapp image 2024-04-25 at 7.28.10 pm.jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-04-25 at 7.28.12 pm (1).jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-05-11 at 6.20.40 pm.jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-05-11 at 6.18.44 pm.jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-05-11 at 6.18.49 pm.jpeg"
            ) &&
            !fileName.includes(
              "whatsapp image 2024-05-11 at 6.18.49 pm (1).jpeg"
            ) &&
            !fileName.includes("whatsapp image 2024-04-25 at 7.28.12 pm.jpeg")
          ) {
            const url = await getDownloadURL(itemRef);
            return {
              url: url,
              metadata: metadata,
            };
          } else {
            return null; // Ignore this image
          }
        });
        const downloadsAndMetadata = await Promise.all(promises);
        const filteredDownloadsAndMetadata = downloadsAndMetadata.filter(
          (item) => item !== null
        ) as { url: string; metadata: FullMetadata }[];
        setImageUrls(filteredDownloadsAndMetadata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImageUrl(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImageUrl(null);
  };

  return (
    <div
      className={
        isTabletOrMobile
          ? "d-flex flex-1 flex-column p-3 w-100 my-5"
          : "d-flex flex-column p-3 w-100 h-100 my-5"
      }
    >
      <h2 className="text-center mt-0 pt-0 mb-4 fw-bold">Produk Kami</h2>
      {isTabletOrMobile ? (
        <div className="w-100 h-auto d-flex flex-column align-items-center justify-content-center">
          {imageUrls && imageUrls?.length !== 0 ? (
            imageUrls.map((image, index) => (
              <div key={index} className="w-100 mb-4 card hover-product">
                <img
                  src={image.url}
                  alt={image.metadata.name}
                  onClick={() => handleImageClick(image.url)}
                  className="card-img-top"
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                    width: "100%",
                    height: "300px", // Adjust height for consistent card size
                  }}
                />
                <div className="card-body">
                  <p className="card-text text-center">Product</p>
                </div>
              </div>
            ))
          ) : (
            <>
              <Skeleton
                style={{
                  height: "320px",
                  width: "320px",
                  marginBottom: '15px'
                }}
              />
              <Skeleton
                style={{
                  height: "320px",
                  width: "320px",
                  marginBottom: '15px'
                }}
              />
              <Skeleton
                style={{
                  height: "320px",
                  width: "320px",
                  marginBottom: '15px'
                }}
              />
              <Skeleton
                style={{
                  height: "320px",
                  width: "320px",
                  marginBottom: '15px'
                }}
              />
              <Skeleton
                style={{
                  height: "320px",
                  width: "320px",
                  marginBottom: '15px'
                }}
              />
            </>
          )}
        </div>
      ) : (
        <div className="w-100 h-100 d-flex flex-row flex-wrap align-items-center justify-content-center column-gap-5">
          {imageUrls && imageUrls.length !== 0 ? (
            imageUrls.map((image, index) => (
              <div key={index} className="mb-4 card hover-product">
                <img
                  src={image.url}
                  alt={image.metadata.name}
                  onClick={() => handleImageClick(image.url)}
                  className="card-img-top"
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                    width: "300px",
                    height: "300px", // Adjust height for consistent card size
                  }}
                />
                <div className="card-body">
                  <p className="card-text text-center">Product</p>
                </div>
              </div>
            ))
          ) : (
            <Skeleton
              style={{
                width: "95vw",
                height: "320px",
              }}
            />
          )}
        </div>
      )}

      {/* Modal for displaying full-size image */}
      {selectedImageUrl && (
        <div
          className={`modal fade ${selectedImageUrl ? "show" : ""}`}
          style={{
            display: selectedImageUrl ? "block" : "none",
            zIndex: 999999,
          }}
          tabIndex={-1}
          aria-labelledby="imageModalLabel"
          aria-hidden={!selectedImageUrl}
        >
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content bg-light">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center">
                <img
                  src={selectedImageUrl}
                  alt="Full Size"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
